const Intro = () => {
  return (
    <div className="subsection">
      <h2></h2>
      <div className="content">
        <p></p>
      </div>
    </div>
  );
};

export default Intro;
